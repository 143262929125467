<template>


<div style="min-height: 100vh;" class="bg-white">
  <b-card no-body>
    <b-card-header>
      <h4>{{ $t('fibreProfile') }}</h4>
    </b-card-header>
    <b-card-body style="padding: 0">
      <Baseinfo v-if="detailForm" :detail="detailForm" />
    </b-card-body>
  </b-card>


</div>



</template>

<script>

import { setLanguageByBrowser, getLanguage } from "@/i18n";
import {
  getPulicFibreDetail,
} from "@/service";
import Baseinfo from "@/views/mobile/garment/components/baseinfo.vue";

export default {
  components: {
    // DetailTopBaseCard,
    Baseinfo,
  },
  data() {
    return {
      /* 面包屑 */
      PageHeaderT: this.$t("garment.detail.title"),
      PageHeaderItems: [
        {
          text: this.$t("garment.title"),
          active: true,
          href: "/garment",
        },
        {
          text: this.$t("garment.detail.title"),
          active: true,
        },
      ],
      showType: this.$route.query.type || "",
      /* tab 信息 */
      tabInfo: [
        {
          type: 'hand',
          title: this.$t('radar_chart.feel')
        },
        {
          type: 'skin',
          title: this.$t('radar_chart.skin')
        },
        {
          type: 'heat',
          title: this.$t('radar_chart.heat')
        },
      ],
      detailForm: null,
      searchList: null,
      current_tab: 'hand',
      /* 提交数据表单 */
      submitForm: {},
      /* 分页 */
      tab_info_length: 0,
      page: 1,
      perPage: 10,
      prePageOption: [10, 20, 30, 40, 50],
      pages: [],
      alertInfo: {
        variant: "danger",
        content: "",
        icon: "ri-error-warning-line",
      },
      formValidataAlert: false,
      is_public: [],
      sankey: {},
    };
  },
  watch: {

  },
  computed: {

  },
  beforeCreate() {
    setLanguageByBrowser()
    this.$i18n.locale = getLanguage()
  },
  mounted() {
    this.initDetail()
  },
  methods: {



    initDetail() {
      // 从path中获取ID
      const id = this.$route.params.id;
      getPulicFibreDetail(id)
        .then((res) => {
          if (res && res.payload && res.payload.data) {
            this.detailForm = res.payload.data;
            this.searchList = res.payload.search_list;
            if (res.payload.data && res.payload.data.is_public) {
              this.is_public = [this.$t("base.is_public")];
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 切换tab展示内容 */
    handlerToggleTab(item) {
      if (this.current_tab === item.type) {
        return;
      }
      this.current_tab = item.type;
    },
  },
};
</script>

<style lang="scss">
.table-responsive {
  min-height: 60vh;
  max-height: 60vh;
}

.mask_model {
  width: 100vw;
  height: 100vh;
  background-color: rgba(47, 79, 79, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
